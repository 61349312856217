import FolderIcon from '@material-ui/icons/Folder'
import CallSplitIcon from '@material-ui/icons/CallSplit'
import AccountBalanceWalletIcon from '@material-ui/icons/AccountBalanceWallet'
import React, { useContext, useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'
import {
  ACCOUNTS,
  BUSINESS_SETTINGS,
  RESTING_AREA,
  STRATEGIES,
  STRATEGY,
} from 'src/presentation/routes'
import { AuthContext } from 'src/context/AuthenticationContext'
import PlaylistAddCheckOutlinedIcon from '@material-ui/icons/PlaylistAddCheckOutlined'
import SettingsApplicationsOutlinedIcon from '@material-ui/icons/SettingsApplicationsOutlined'
import SettingsSystemDaydreamOutlinedIcon from '@material-ui/icons/SettingsSystemDaydreamOutlined'
import { Icon, SideMenu, SideMenuItem } from 'everchain-uilibrary'
import { useGetUserBusinessType } from 'src/context/UserContext'
import {
  AppsPermissionCodeAccess,
  PermissionCodeAccess,
} from 'src/utils/constants'
import { AbilityContext } from 'src/context/Can'
import { getStandardUri, permissionReduce } from 'src/utils/common'
import SupervisorAccountIcon from '@material-ui/icons/SupervisorAccount'

const Menus = () => {
  const history = useHistory()
  const ability = useContext(AbilityContext)
  const canCreateStrategy = ability.can(
    PermissionCodeAccess.Recover_CreateStrategies,
    'any'
  )
  const { isVendor } = useGetUserBusinessType()
  const [pageUrl, setPageUrl] = useState(history.location.pathname)
  const { managePermissions, userPermissions } = useContext(AuthContext)
  const isInternal = userPermissions.type.toLowerCase() === 'internal'
  const handleInternalRedirect = (uri: string) => {
    window.localStorage.removeItem('strategiesLoaded')
    window.localStorage.removeItem('filesFilterStorage')
    window.localStorage.removeItem('accountsFilterStorage')
    window.localStorage.removeItem('accountsFiltered')
    history.push(getStandardUri(uri))
  }

  const managePermissionReduce = permissionReduce(managePermissions)

  useEffect(() => {
    setPageUrl(history.location.pathname)
  }, [history.location.pathname])

  return (
    <SideMenu>
      <SideMenuItem
        id="menu-accounts"
        title="Accounts"
        onClick={() => handleInternalRedirect(ACCOUNTS)}
        icon={<FolderIcon fontSize="small" />}
        defaultOpen={pageUrl.indexOf(ACCOUNTS) !== -1}
      />
      {(isInternal || !isVendor) && (
        <SideMenuItem
          id="recover-item-resting-area"
          title="Resting Area"
          onClick={() => handleInternalRedirect(RESTING_AREA)}
          icon={<Icon name="Pending" />}
          defaultOpen={pageUrl.indexOf(RESTING_AREA) !== -1}
        />
      )}
      {canCreateStrategy && !isVendor && (
        <SideMenuItem
          id="menu-strategies"
          title="Strategies"
          onClick={() => handleInternalRedirect(STRATEGIES)}
          icon={<CallSplitIcon fontSize="small" />}
          defaultOpen={
            pageUrl.indexOf(STRATEGIES) !== -1 ||
            pageUrl.indexOf(STRATEGY) !== -1
          }
        />
      )}
      {isInternal && (
        <SideMenuItem
          id="recover-item-business-settings"
          title="Business Settings"
          onClick={() => handleInternalRedirect(BUSINESS_SETTINGS)}
          icon={<SupervisorAccountIcon fontSize="small" />}
          defaultOpen={pageUrl.indexOf(BUSINESS_SETTINGS) !== -1}
        />
      )}
      {managePermissionReduce[AppsPermissionCodeAccess.Marketplace] && (
        <SideMenuItem
          id="menu-marketplace"
          title="Marketplace"
          onClick={() =>
            window.open(process.env.REACT_APP_MARKETPLACE_SITE, '_self')
          }
          icon={<AccountBalanceWalletIcon fontSize="small" />}
        />
      )}
      {managePermissionReduce[AppsPermissionCodeAccess.Control_Panel] && (
        <SideMenuItem
          id="menu-control-panel"
          title="Control Panel"
          onClick={() =>
            window.open(process.env.REACT_APP_CONTROLPANEL_SITE, '_self')
          }
          icon={<SettingsApplicationsOutlinedIcon fontSize="small" />}
        />
      )}
      {managePermissionReduce[
        AppsPermissionCodeAccess.Compliance_Management_System
      ] && (
        <SideMenuItem
          id="menu-compliance"
          title="Compliance Management"
          onClick={() => window.open(process.env.REACT_APP_CMS_SITE, '_self')}
          icon={<PlaylistAddCheckOutlinedIcon fontSize="small" />}
        />
      )}
      {managePermissionReduce[AppsPermissionCodeAccess.Monitor] && (
        <SideMenuItem
          id="menu-monitoring"
          title="Monitoring"
          onClick={() =>
            window.open(process.env.REACT_APP_MONITOR_SITE, '_self')
          }
          icon={<SettingsSystemDaydreamOutlinedIcon fontSize="small" />}
        />
      )}
    </SideMenu>
  )
}

Menus.defaultProps = {
  opendedMenu: false,
}

export default Menus
