/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-console */
import { Can } from 'src/context/Can'
import { Box, Grid, List, ListItem } from '@material-ui/core'
import React, { useContext, useState, useEffect } from 'react'
import Creditors from '../Accounts/components/Creditors'
import { IAccountGetOperations } from 'src/domain/features/get/account/account'
import { IBusinessGetOperations } from 'src/domain/features/get/business/business'
import {
  Button,
  DataTable,
  DataTableState,
  renderDate,
  renderCurrency,
  Content,
  Switch,
  useForm,
  Dropdown,
  fNumberCurrency,
  ButtonDropdown,
  ButtonDropdownItem,
  ModalDialog,
  Colors,
  Typography,
  UploadDragDrop,
  Step,
  renderCheckbox,
  SuccessToast,
  WarningToast,
  fParseXlsxFile,
} from 'everchain-uilibrary'
import { IFileGetOperations } from 'src/domain/features/get/file/file'
import DateAccountFilter from '../Accounts/components/DateAccountFilters'
import { Account } from 'src/domain/models/accounts'
import { useHistory } from 'react-router-dom'
import {
  ACCOUNT_DETAIL,
  CHARGE_OFF_ACCOUNT_FILE_VALIDATION,
} from 'src/presentation/routes'

import { profileCountry } from 'src/utils/user'
import { useGetUserBusinessType } from 'src/context/UserContext'
import { AuthContext } from 'src/context/AuthenticationContext'
import { getStandardUri, handleBulkSelectRows } from 'src/utils/common'
import { IAssetTypesGetOperations } from 'src/domain/features/get/assetTypes/assetTypes'
import { useMutation, useQuery } from '@tanstack/react-query'
import { IStrategyGetOperations } from 'src/domain/features/get/strategy/strategy'
import { IAccountPostOperations } from 'src/domain/features/post/account/account'
import { dateThreeMonthsPast } from 'src/utils/date'
import { generateFileBlob } from 'src/utils/file/fileGenerator'
import { FileTypeEnum, PermissionCodeAccess } from 'src/utils/constants'
import { useCustomQuery } from 'src/infra/reactQuery'

interface RestingAreaParams {
  accountOperations?: IAccountGetOperations
  businessOperations?: IBusinessGetOperations
  fileOperations?: IFileGetOperations
  assetTypeOperations?: IAssetTypesGetOperations
  strategyOperations?: IStrategyGetOperations
  accountPostOperations: IAccountPostOperations
}

export interface FormFilter {
  creditorId: string | undefined
  dateAccountFrom?: Date
  dateAccountTo?: Date
  chargeOff?: boolean
  assetTypeId?: string | undefined
  dateTypeFilter?: any
}

const RestingArea: React.FC<RestingAreaParams> = ({
  businessOperations,
  accountOperations,
  fileOperations,
  assetTypeOperations,
  strategyOperations,
  accountPostOperations,
}) => {
  const [assetTypes, setAssetTypes] = useState<any>()

  const getPersistedAccountFilterData = () => {
    const data = window.localStorage.getItem('accountsRestingAreaFilterStorage')
    const parsedData = data ? JSON.parse(data) : {}

    return {
      ...parsedData,
      dateFrom: parsedData.dateFrom || dateThreeMonthsPast(),
      dateTo: parsedData.dateTo || new Date(),
    }
  }
  const getAccountsSelectedData = () => {
    if (window.localStorage.getItem('accountsSelected') !== null) {
      return JSON.parse(window.localStorage.getItem('accountsSelected') ?? '')
    }
    return []
  }

  const getPersistedAccountData = () => {
    if (window.localStorage.getItem('accountsRestingAreaFiltered') !== null) {
      return JSON.parse(
        window.localStorage.getItem('accountsRestingAreaFiltered') ?? ''
      )
    }
  }

  const [loadingData, setLoadingData] = useState<boolean>(false)
  const [files, setFiles] = useState<File[]>([])
  const [typeUpload, setTypeUpload] = useState<string>('')
  const [openUploadModal, setOpenUploadModal] = useState<boolean>(false)
  const [openChargeOffUploadModal, setOpenChargeOffUploadModal] =
    useState<boolean>(false)
  const [openConfirmationModal, setOpenConfirmationModal] =
    useState<boolean>(false)
  const [openChargeOffConfirmationModal, setOpenChargeOffConfirmationModal] =
    useState<boolean>(false)
  const [totalAccounts, setTotalAccounts] = useState<number>(
    getPersistedAccountData()?.totalCount | 0
  )
  const [accounts, setAccounts] = useState<Account[] | undefined>(
    getPersistedAccountData()?.accounts || []
  )
  const [totalFaceValue, setTotalFaceValue] = useState<number | undefined>(0)
  const [form, setForm] = useState<FormFilter | undefined>(
    getPersistedAccountFilterData() || ''
  )
  const [lastFilterUsed, setLastFilterUsed] = useState<FormFilter | undefined>(
    form
  )
  const methods = useForm({ defaultValues: form })
  useEffect(() => {
    if (!!form?.creditorId) {
      setLoadingData(true)
      fetchAssetTypes()
    }
  }, [form])
  const fetchAssetTypes = (assetTypeId?: string) => {
    assetTypeOperations
      ?.getAssetTypeData(form?.creditorId ?? '')
      .then((data: any) => {
        setAssetTypes(data)
        if (assetTypeId) {
          methods.setValue('assetTypeId', assetTypeId)
        }
        setLoadingData(false)
      })
      .catch((error: any) => {
        setLoadingData(false)
      })
    setLoadingData(false)
  }

  const [gridState, setGridState] = useState<DataTableState>({
    skip: 0,
    take: 25,
    filter: undefined,
    sort: undefined,
  })
  const history = useHistory()
  const { isCreditor } = useGetUserBusinessType()

  const { userPermissions } = useContext(AuthContext)
  const isInternal = userPermissions.type.toLowerCase() === 'internal'

  const {
    isFetching: fetchingAccounts,
    isLoading: loadingAccounts,
    refetch: refetchAccounts,
  } = useCustomQuery(
    ['getAccountsRestingArea'],
    async () =>
      accountOperations
        ?.getAccountsRestingArea(gridState, form)
        .then((data) => {
          setTotalFaceValue(data?.totalFaceValue)
          setAccounts(data.data)
          setTotalAccounts(data.totalCount ?? 0)
          setLoadingData(false)
          setLastFilterUsed(form)
          window.localStorage.setItem(
            'accountsRestingAreaFiltered',
            JSON.stringify({
              accounts: data?.data,
              totalCount: data?.totalCount,
            })
          )
        }),
    { cacheTime: 0 }
  )

  const handleChecked = (props: any) => {
    window.localStorage.setItem(
      'accountsSelected',
      JSON.stringify(accountsSelected)
    )
    return !!accountsSelected.find((x) => x === props.dataItem['ecaid'])
  }
  const cleanItems = () => {
    setFormSendStrategy({})
    setOpenUploadModal(false)
    setFiles([])
    setAccountsSelected([])
  }
  const mutationSendAccountToStrategy = useMutation({
    mutationFn: async (params: any) => {
      return accountPostOperations.processSendAccountToStrategy(
        formSendStrategy.creditorId,
        typeUpload === 'allAccounts' ? [] : accountsSelected,
        files[0] ?? null,
        lastFilterUsed,
        formSendStrategy.strategy
      )
    },
    onSuccess: async (response: any) => {
      if (response.data.length > 0) {
        WarningToast(response.data[0].errorMessage)
      } else {
        SuccessToast('Accounts successfully sent')
      }
      refetchAccounts()
      cleanItems()
      setLoadingData(false)
      setOpenUploadModal(false)
      setOpenConfirmationModal(false)
    },
    onError: async (response: any) => {
      setLoadingData(false)
      setOpenUploadModal(false)
      setOpenConfirmationModal(false)
    },
  })

  const mutationChargeOffAccounts = useMutation({
    mutationFn: async (params: any) => {
      return accountPostOperations.processChargeOffAccounts(
        chargeOffCreditor,
        typeUpload === 'allAccounts' ? [] : accountsSelected,
        files[0] ?? null,
        lastFilterUsed
      )
    },
    onSuccess: async (response: any) => {
      if (response.data.length > 0) {
        WarningToast(response.data[0].errorMessage)
      } else {
        SuccessToast('Accounts successfully charged-off')
      }
      refetchAccounts()
      cleanItems()
      setLoadingData(false)
      setOpenChargeOffUploadModal(false)
      setOpenChargeOffConfirmationModal(false)
    },
    onError: async (response: any) => {
      setLoadingData(false)
      setOpenChargeOffUploadModal(false)
      setOpenChargeOffConfirmationModal(false)
    },
  })

  const GridAccountsColumns = () => {
    return [
      {
        title: '#',
        width: 50,
        show: true,
        notFilterable: true,
        render: (props: any) =>
          renderCheckbox(false, handleChecked(props), (checked) => {
            checked
              ? setAccountsSelected([
                  ...accountsSelected,
                  props.dataItem['ecaid'],
                ])
              : setAccountsSelected(
                  accountsSelected.filter(
                    (id) => id !== props.dataItem['ecaid']
                  )
                )
          }),
      },
      {
        field: 'lenderLoanId',
        title: 'Loan ID',
        show: true,
        width: 150,
      },
      {
        field: 'firstName',
        title: 'First Name',
        width: 150,
        show: !isInternal,
      },
      {
        field: 'lastName',
        title: 'Last Name',
        width: 150,
        show: !isInternal,
      },
      { field: 'lender', title: 'Lender', show: true, width: 150 },
      { field: 'status', title: 'Status', show: true, width: 100 },
      {
        field: 'originalLoanAmount',
        title: 'Orig. Amt.',
        show: true,
        width: 110,
        filter: 'numeric',
        render: (props: any) => renderCurrency(props, profileCountry()),
      },
      {
        field: 'principalBalance',
        title: 'Principal',
        show: true,
        width: 110,
        filter: 'numeric',
        render: (props: any) => renderCurrency(props, profileCountry()),
      },
      {
        field: 'interestBalance',
        title: 'Interest',
        show: true,
        width: 100,
        filter: 'numeric',
        render: (props: any) => renderCurrency(props, profileCountry()),
      },
      {
        field: 'otherFeesBalances',
        title: 'Fee',
        show: true,
        width: 95,
        filter: 'numeric',
        render: (props: any) => renderCurrency(props, profileCountry()),
      },
      {
        field: 'lastPaymentAmount',
        title: 'Amount',
        show: true,
        width: 100,
        filter: 'numeric',
        render: (props: any) => renderCurrency(props, profileCountry()),
      },
      {
        field: 'totalBalance',
        title: 'Total Balance',
        show: true,
        width: 135,
        filter: 'numeric',
        render: (props: any) => renderCurrency(props, profileCountry()),
      },
      {
        field: 'originalDate',
        title: 'Origination Date',
        show: true,
        width: 150,
        render: (props: any) => renderDate(props),
        filter: 'date',
      },
      {
        field: 'defaultDate',
        title: 'Default Date',
        show: true,
        width: 130,
        render: (props: any) => renderDate(props),
        filter: 'date',
      },
      {
        field: 'lastPaymentDate',
        title: 'Last Payment Date',
        show: true,
        width: 165,
        render: (props: any) => renderDate(props),
        filter: 'date',
      },
      {
        field: 'writeOffDate',
        title: 'C/O Date',
        show: true,
        width: 110,
        render: (props: any) => renderDate(props),
        filter: 'date',
      },
      {
        field: 'ecaid',
        title: 'ECAID',
        show: true,
        width: 250,
      },
    ]
  }

  const handleCreditorsUpdate = (creditor: any) => {
    setForm((prevObj: any) => {
      return { ...prevObj, creditorId: creditor }
    })
  }
  const handleCreditorsUpdateSendStrategy = (creditor: any) => {
    setFormSendStrategy((prevObj: any) => {
      return { ...prevObj, creditorId: creditor }
    })
  }

  const handleCreditorsUpdateChargeOff = (creditor: any) => {
    setChargeOffCreditor(creditor)
  }

  const onDateFromAccountFilterUpdate = (dateAccountFrom: any) => {
    console.log(dateAccountFrom)
    setForm((prevObj: any) => {
      return { ...prevObj, dateAccountFrom: dateAccountFrom.toISOString() }
    })
  }

  const onDateToAccountFilterUpdate = (dateAccountTo: any) => {
    setForm((prevObj: any) => {
      return { ...prevObj, dateAccountTo: dateAccountTo.toISOString() }
    })
  }
  const [accountsSelected, setAccountsSelected] = useState<string[]>(
    getAccountsSelectedData()
  )
  const getColumns = () => {
    return GridAccountsColumns()
  }

  const handleFilter = () => {
    setAccountsSelected([])
    window.localStorage.removeItem('accountsSelected')

    if (form)
      window.localStorage.setItem(
        'accountsRestingAreaFilterStorage',
        JSON.stringify(form)
      )
    refetchAccounts()
  }

  const handleRowClick = (event: any) => {
    history.push(getStandardUri(`${ACCOUNT_DETAIL}/${event.dataItem.ecaid}`))
  }
  const handleCloseMultipleSelectionToggle = (event: any) => {
    handleBulkSelectRows(
      event,
      accounts ?? [],
      accountsSelected,
      setAccountsSelected,
      'ecaid'
    )
  }

  const [formSendStrategy, setFormSendStrategy] = useState<any>({
    creditorId: '',
    strategy: '',
  })

  const [chargeOffCreditor, setChargeOffCreditor] = useState<any>()

  const getCreditorsQuery = useQuery({
    queryKey: ['business.getCreditors'],
    queryFn: async () => businessOperations?.getCreditorsData(),
  })

  const getStrategyQuery = useQuery({
    queryKey: ['strategies.getStrategy', formSendStrategy.creditorId],
    queryFn: async () =>
      strategyOperations?.getStrategyByCreditorId(formSendStrategy.creditorId),
    enabled: Boolean(formSendStrategy.creditorId),
  })

  const downloadTemplate = async (templateType: FileTypeEnum) => {
    try {
      const template = await fileOperations?.getTemplateFile(templateType)
      generateFileBlob(template)
    } catch (err) {
      console.error(err)
    }
  }

  const strategyList = getStrategyQuery?.data || []
  return (
    <Content id="accounts">
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between',
          marginBottom: '5vh',
        }}
      >
        <Typography variant="h5">Resting Area</Typography>
      </div>

      <Grid container justifyContent="space-between">
        <Box
          display="flex"
          alignItems="flex-end"
          alignContent="flex-end"
          gridGap={10}
        >
          {(isInternal || isCreditor) && (
            <Grid item xs="auto">
              <Creditors
                businessOperations={businessOperations}
                onCreditorsUpdate={handleCreditorsUpdate}
                selectedCreditor={getPersistedAccountFilterData()?.creditorId}
              />
            </Grid>
          )}
          <Grid item xs="auto">
            <Dropdown
              isLoading={loadingData || fetchingAccounts}
              isFetching={loadingData || fetchingAccounts}
              id="assetTypeId-dropdown"
              placeholder="Asset Type"
              allowEmptyValue
              onChange={(option?: any) => {
                setForm((prevObj: any) => ({
                  ...prevObj,
                  assetTypeId: option?.id,
                }))
              }}
              options={assetTypes?.sort((a: any, b: any) =>
                a.name.localeCompare(b.name)
              )}
              valueOptionName="id"
              labelOptionName="name"
              disabled={!form?.creditorId || loadingData || fetchingAccounts}
            />
          </Grid>
          <Grid item xs="auto">
            <DateAccountFilter
              onDateFromFilterUpdate={onDateFromAccountFilterUpdate}
              onDateToFilterUpdate={onDateToAccountFilterUpdate}
              selectedFromDate={
                getPersistedAccountFilterData()?.dateAccountFrom
              }
              selectedToDate={getPersistedAccountFilterData()?.dateAccountTo}
            >
              <Dropdown
                isLoading={loadingData || fetchingAccounts}
                isFetching={loadingData || fetchingAccounts}
                id="typeDateFilter-dropdown"
                value={form?.dateTypeFilter}
                placeholder="Date type"
                onChange={(option?: any) => {
                  setForm((prevObj: any) => ({
                    ...prevObj,
                    dateTypeFilter: option?.id,
                  }))
                }}
                options={[
                  { id: 1, name: 'Origination Date' },
                  { id: 2, name: 'Fund Date' },
                  { id: 3, name: '1st Delinquency Date' },
                  { id: 4, name: 'Last Delinquency Date' },
                  { id: 5, name: 'Last Payment Date' },
                  { id: 6, name: 'Default Date' },
                  { id: 7, name: 'Charged Off Date' },
                  { id: 8, name: 'Upload Date' },
                ]}
                valueOptionName="id"
                labelOptionName="name"
              />
            </DateAccountFilter>
          </Grid>
          <Grid style={{ paddingLeft: '10px' }} item xs="auto">
            <Switch
              onClick={() =>
                setForm((prevObj: any) => ({
                  ...prevObj,
                  chargeOff: !form?.chargeOff,
                }))
              }
              checked={form?.chargeOff ?? false}
              label={<Typography>Charged Off Accounts</Typography>}
            />
          </Grid>
          \
        </Box>

        <Grid item xs="auto" style={{ paddingBottom: '13px' }}>
          <Button
            useRipple
            width={80}
            height={40}
            onClick={handleFilter}
            isLoading={loadingData || fetchingAccounts || loadingAccounts}
            disabled={!isInternal && isCreditor && form?.creditorId == null}
          >
            Filter
          </Button>
        </Grid>
      </Grid>

      <Box mt={5}>
        <Grid item style={{ display: 'flex', alignItems: 'center', gap: 10 }}>
          <Box>
            <ButtonDropdown
              width="210px"
              buttontext="Bulk Selection Options"
              id="simple-menu"
              variant="secondary"
              mainButtonDropdownChildren={
                <>
                  <ButtonDropdownItem
                    onClick={() =>
                      handleCloseMultipleSelectionToggle(
                        'Select all rows (current page)'
                      )
                    }
                  >
                    Select all rows (current page)
                  </ButtonDropdownItem>
                  <ButtonDropdownItem
                    onClick={() =>
                      handleCloseMultipleSelectionToggle(
                        'Unselect all rows (current page)'
                      )
                    }
                  >
                    Unselect all rows (current page)
                  </ButtonDropdownItem>

                  <ButtonDropdownItem
                    onClick={() =>
                      handleCloseMultipleSelectionToggle(
                        'Unselect all rows (all pages)'
                      )
                    }
                  >
                    Unselect all rows (all pages)
                  </ButtonDropdownItem>
                </>
              }
              enableSideButton={false}
              doubleDropDown={true}
            ></ButtonDropdown>
          </Box>
          <Box>
            <Typography style={{ marginBottom: 0 }}>
              Total of accounts selected: {accountsSelected.length}
            </Typography>
          </Box>
          <Box>
            <ButtonDropdown
              buttontext="Send to strategy"
              width="180px"
              variant="primary"
              mainButtonDropdownChildren={
                <>
                  <ButtonDropdownItem
                    onClick={() => {
                      setOpenConfirmationModal(true)
                      setTypeUpload('filteredAccounts')
                    }}
                    disabled={!accountsSelected.length}
                  >
                    Send selected accounts
                  </ButtonDropdownItem>
                  <ButtonDropdownItem
                    disabled={!accounts?.length}
                    onClick={() => {
                      setOpenConfirmationModal(true)
                      setTypeUpload('allAccounts')
                    }}
                  >
                    Send all accounts
                  </ButtonDropdownItem>
                  <ButtonDropdownItem onClick={() => setOpenUploadModal(true)}>
                    Upload file
                  </ButtonDropdownItem>
                </>
              }
              enableSideButton={false}
              doubleDropDown={true}
            ></ButtonDropdown>
          </Box>
          <Can
            do={PermissionCodeAccess.Recover_ManuallyChargeOffAccounts}
            on="any"
          >
            <Box>
              <ButtonDropdown
                buttontext="Charge Off Accounts"
                width="210px"
                variant="primary"
                doubleDropDown={true}
                mainButtonDropdownChildren={
                  <>
                    <ButtonDropdownItem
                      disabled={!accountsSelected.length}
                      onClick={() => {
                        setOpenChargeOffConfirmationModal(true)
                        setTypeUpload('filteredAccounts')
                      }}
                    >
                      Charge-Off selected accounts
                    </ButtonDropdownItem>
                    <ButtonDropdownItem
                      disabled={!accounts?.length}
                      onClick={() => {
                        setOpenChargeOffConfirmationModal(true)
                        setTypeUpload('allAccounts')
                      }}
                    >
                      Charge-Off all accounts
                    </ButtonDropdownItem>
                    <ButtonDropdownItem
                      onClick={() => setOpenChargeOffUploadModal(true)}
                    >
                      Upload accounts file
                    </ButtonDropdownItem>
                    <ButtonDropdownItem
                      onClick={() =>
                        downloadTemplate(FileTypeEnum.ChargeOffAccounts)
                      }
                    >
                      Download Template
                    </ButtonDropdownItem>
                  </>
                }
                enableSideButton={false}
              ></ButtonDropdown>
            </Box>
          </Can>
        </Grid>
      </Box>
      <ModalDialog
        header="Confirmation"
        isOpen={openConfirmationModal}
        onClose={() => {
          setOpenConfirmationModal(false)
          setFormSendStrategy({})
          setFiles([])
        }}
        buttonOkText="Confirm"
        buttonCancelText="Cancel"
        disableOkButton={
          !formSendStrategy.creditorId ||
          !formSendStrategy.strategy ||
          loadingData ||
          fetchingAccounts
        }
        onContinue={() => {
          setLoadingData(true)
          mutationSendAccountToStrategy.mutate(null)
        }}
      >
        <>
          <List>
            <ListItem style={{ paddingBottom: '14px' }}>
              <Step
                completed={!!formSendStrategy?.creditorId}
                title="Select the business"
                stepNumber="1"
              >
                <Creditors
                  businessOperations={businessOperations}
                  onCreditorsUpdate={handleCreditorsUpdateSendStrategy}
                  selectedCreditor={formSendStrategy?.creditorId}
                />
              </Step>
            </ListItem>
            <ListItem style={{ paddingBottom: '14px' }}>
              <Step
                completed={formSendStrategy?.strategy}
                title="Select the strategy"
                stepNumber="2"
              >
                <Dropdown
                  onChange={(props: any) =>
                    setFormSendStrategy((prevObj: any) => ({
                      ...prevObj,
                      strategy: props?.id,
                    }))
                  }
                  isLoading={getStrategyQuery.isFetching}
                  width={'350px'}
                  disabled={
                    !formSendStrategy?.creditorId ||
                    getCreditorsQuery.isFetching
                  }
                  options={strategyList}
                  placeholder="Strategy"
                  value={formSendStrategy?.strategy}
                  valueOptionName="id"
                  labelOptionName="name"
                ></Dropdown>
              </Step>
            </ListItem>

            <ListItem style={{ paddingBottom: '14px' }}>
              <Typography
                color={Colors.primary}
                variant="caption"
                style={{ fontSize: 18 }}
              >
                {typeUpload === 'filteredAccounts'
                  ? `Do you confirm that you want to send ${accountsSelected.length} Accounts?`
                  : `Do you confirm that you want to send ${totalAccounts} Accounts?`}
              </Typography>
            </ListItem>
          </List>
        </>
      </ModalDialog>
      <ModalDialog
        header="Confirmation"
        isOpen={openUploadModal}
        onClose={() => {
          setOpenUploadModal(false)
          setFormSendStrategy({})
          setFiles([])
        }}
        buttonOkText="Confirm"
        buttonCancelText="Cancel"
        disableOkButton={
          !files.length ||
          !formSendStrategy.creditorId ||
          !formSendStrategy.strategy ||
          loadingData ||
          fetchingAccounts
        }
        onContinue={() => {
          setLoadingData(true)
          mutationSendAccountToStrategy.mutate(null)
        }}
      >
        <List>
          <ListItem style={{ paddingBottom: '14px' }}>
            <Step
              completed={!!formSendStrategy?.creditorId}
              title="Select the business"
              stepNumber="1"
            >
              <Creditors
                businessOperations={businessOperations}
                onCreditorsUpdate={handleCreditorsUpdateSendStrategy}
                selectedCreditor={formSendStrategy?.creditorId}
              />
            </Step>
          </ListItem>
          <ListItem style={{ paddingBottom: '14px' }}>
            <Step
              completed={formSendStrategy?.strategy}
              title="Select the strategy"
              stepNumber="2"
            >
              <Dropdown
                onChange={(props: any) =>
                  setFormSendStrategy((prevObj: any) => ({
                    ...prevObj,
                    strategy: props?.id,
                  }))
                }
                isLoading={getStrategyQuery.isFetching}
                width={'350px'}
                disabled={
                  !formSendStrategy?.creditorId || getCreditorsQuery.isFetching
                }
                options={strategyList}
                placeholder="Strategy"
                value={formSendStrategy?.strategy}
                valueOptionName="id"
                labelOptionName="name"
              ></Dropdown>
            </Step>
          </ListItem>

          <ListItem style={{ paddingBottom: '14px' }}>
            <Step
              completed={!!files.length}
              title="Select the file"
              stepNumber="2"
            >
              <Grid>
                <UploadDragDrop
                  files={files}
                  setFiles={setFiles}
                  hideUploadButton={true}
                ></UploadDragDrop>
              </Grid>
            </Step>
          </ListItem>
        </List>
      </ModalDialog>

      <ModalDialog
        header="Charge-Off Confirmation"
        isOpen={openChargeOffConfirmationModal}
        onClose={() => {
          setOpenChargeOffConfirmationModal(false)
          setFiles([])
        }}
        buttonOkText="Confirm"
        buttonCancelText="Cancel"
        disableOkButton={!chargeOffCreditor || loadingData || fetchingAccounts}
        onContinue={() => {
          setLoadingData(true)
          mutationChargeOffAccounts.mutate(null)
        }}
      >
        <>
          <List>
            <ListItem style={{ paddingBottom: '14px' }}>
              <Step
                completed={!!chargeOffCreditor}
                title="Select the business"
                stepNumber="1"
              >
                <Creditors
                  businessOperations={businessOperations}
                  onCreditorsUpdate={handleCreditorsUpdateChargeOff}
                  selectedCreditor={chargeOffCreditor}
                />
              </Step>
            </ListItem>
            <ListItem style={{ paddingBottom: '14px' }}>
              <Typography
                color={Colors.primary}
                variant="caption"
                style={{ fontSize: 18 }}
              >
                {typeUpload === 'filteredAccounts'
                  ? `Do you confirm that you want to charge-off ${accountsSelected.length} Accounts?`
                  : `Do you confirm that you want to charge-off ${totalAccounts} Accounts?`}
              </Typography>
            </ListItem>
          </List>
        </>
      </ModalDialog>
      <ModalDialog
        header="Charge-Off Confirmation"
        isOpen={openChargeOffUploadModal}
        onClose={() => {
          setOpenChargeOffUploadModal(false)
          setFiles([])
        }}
        buttonOkText="Confirm"
        buttonCancelText="Cancel"
        disableOkButton={
          !files.length || !chargeOffCreditor || loadingData || fetchingAccounts
        }
        onContinue={() => {
          setLoadingData(true)
          if (files[0]) {
            fParseXlsxFile(files[0]).then((result: any) => {
              history.push({
                pathname: getStandardUri(CHARGE_OFF_ACCOUNT_FILE_VALIDATION),
                state: {
                  creditorId: chargeOffCreditor,
                  fileData: result.data,
                  fileUploaded: files[0],
                },
              })
            })
          }
        }}
      >
        <List>
          <ListItem style={{ paddingBottom: '14px' }}>
            <Step
              completed={!!chargeOffCreditor}
              title="Select the business"
              stepNumber="1"
            >
              <Creditors
                businessOperations={businessOperations}
                onCreditorsUpdate={handleCreditorsUpdateChargeOff}
                selectedCreditor={chargeOffCreditor}
              />
            </Step>
          </ListItem>
          <ListItem style={{ paddingBottom: '14px' }}>
            <Step
              completed={!!files.length}
              title="Select the file"
              stepNumber="2"
            >
              <Grid>
                <UploadDragDrop
                  files={files}
                  setFiles={setFiles}
                  hideUploadButton={true}
                ></UploadDragDrop>
              </Grid>
            </Step>
          </ListItem>
        </List>
      </ModalDialog>
      {totalFaceValue && totalFaceValue > 0 && (
        <Box mt={5}>
          <Grid item style={{ display: 'flex', alignItems: 'center' }}>
            <Box marginRight={2}>
              <Typography>
                Total face value: {fNumberCurrency(totalFaceValue)}
              </Typography>
            </Box>
          </Grid>
        </Box>
      )}
      <Box mt={3}>
        <DataTable
          sortable={true}
          useFilterMenu={true}
          isLoading={loadingData || fetchingAccounts || loadingAccounts}
          height="100%"
          maxHeight="100%"
          gridColumns={getColumns() || []}
          gridState={gridState}
          data={accounts}
          pageable={true}
          total={totalAccounts}
          onRowClick={handleRowClick}
          onDataStateChange={(e: { dataState: any }) => {
            setGridState(e.dataState)
            if (accounts) {
              refetchAccounts()
            }
          }}
        />
      </Box>
    </Content>
  )
}

export default RestingArea
