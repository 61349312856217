import { BACKEND_URL } from 'src/infra/http/httpClient'

const accountsPrefix = 'accounts.'

export const AccountsProcessAccountFile = `${BACKEND_URL}${accountsPrefix}processAccountFile`
export const AccountsProcessRecallAccountFile = `${BACKEND_URL}${accountsPrefix}processRecallAccountFile`
export const AccountsProcessReturnAccountFile = `${BACKEND_URL}${accountsPrefix}processReturnAccountFile`
export const AccountsProcessRecallBusinessAccounts = `${BACKEND_URL}${accountsPrefix}processRecallBusinessAccounts`
export const AccountsProcessReturnBusinessAccounts = `${BACKEND_URL}${accountsPrefix}processReturnBusinessAccounts`
export const AccountsCheckVendorAccountLoad = `${BACKEND_URL}${accountsPrefix}checkVendorAccountLoad`
export const AccountsProcessUpdateAccountFile = `${BACKEND_URL}${accountsPrefix}processUpdateAccountFile`
export const AccountsProcessSendAccountToStrategy = `${BACKEND_URL}${accountsPrefix}processSendAccountToStrategy`
export const AccountsProcessChargeOffAccounts = `${BACKEND_URL}${accountsPrefix}processChargeOffAccounts`
