/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState } from 'react'
import { List, ListItem } from '@material-ui/core'
import {
  Dropdown,
  ModalDialog,
  Step,
  UploadDragDrop,
  fParseXlsxFile,
} from 'everchain-uilibrary'
import { IBusinessGetOperations } from 'src/domain/features/get/business/business'
import { useQuery } from '@tanstack/react-query'
import { IStrategyGetOperations } from 'src/domain/features/get/strategy/strategy'
import { useHistory } from 'react-router-dom'
import { ACCOUNT_FILE_VALIDATION } from 'src/presentation/routes'
import { getStandardUri } from 'src/utils/common'

interface LoadAccountsModalProps {
  open: boolean
  setOpenLoadModal: any
  businessOperations: IBusinessGetOperations
  strategyOperations: IStrategyGetOperations
}

const LoadAccountsModal: React.FC<LoadAccountsModalProps> = ({
  open,
  setOpenLoadModal,
  businessOperations,
  strategyOperations,
}: LoadAccountsModalProps) => {
  const history = useHistory()
  const [form, setForm] = useState<any>({
    creditorId: '',
    strategy: '',
  })
  const [files, setFiles] = useState<File[]>([])

  const getCreditorsQuery = useQuery({
    queryKey: ['business.getCreditors'],
    queryFn: async () => businessOperations.getCreditorsData(),
  })

  const creditorList = getCreditorsQuery?.data || []

  const getStrategyQuery = useQuery({
    queryKey: ['strategies.getStrategy', form.creditorId],
    queryFn: async () =>
      strategyOperations.getStrategyByCreditorId(form.creditorId),
    enabled: Boolean(form.creditorId),
  })

  const strategyList = getStrategyQuery?.data || []

  return (
    <>
      <ModalDialog
        isOpen={open}
        buttonOkText="Upload"
        onClose={() => {
          setOpenLoadModal(false)
          setForm({
            ...form,
            creditorId: '',
            strategy: '',
          })
        }}
        onContinue={() => {
          if (files[0]) {
            fParseXlsxFile(files[0]).then((result: any) => {
              history.push({
                pathname: getStandardUri(ACCOUNT_FILE_VALIDATION),
                state: {
                  creditorId: form.creditorId,
                  strategyId: form.strategy,
                  fileData: result.data,
                  fileUploaded: files[0],
                },
              })
            })
          }
        }}
        header="Load Accounts"
      >
        <List>
          <ListItem style={{ paddingBottom: '14px' }}>
            <Step
              completed={form.creditorId}
              title="Select the business"
              stepNumber="1"
            >
              <Dropdown
                width={'350px'}
                options={creditorList}
                isLoading={
                  getCreditorsQuery.isFetching || getCreditorsQuery.isLoading
                }
                placeholder="Business"
                onChange={(props: any) => {
                  setForm({
                    ...form,
                    creditorId: props?.id,
                  })
                }}
                value={form.creditorId}
                valueOptionName="id"
                labelOptionName="name"
              ></Dropdown>
            </Step>
          </ListItem>
          <ListItem style={{ paddingBottom: '14px' }}>
            <Step
              completed={form.strategy}
              title="Select the strategy"
              stepNumber="2"
            >
              <Dropdown
                onChange={(props: any) =>
                  setForm({
                    ...form,
                    strategy: props?.id,
                  })
                }
                isLoading={getStrategyQuery.isFetching}
                width={'350px'}
                disabled={!form.creditorId || getCreditorsQuery.isFetching}
                options={strategyList}
                placeholder="Strategy"
                value={form.strategy}
                valueOptionName="id"
                labelOptionName="name"
              ></Dropdown>
            </Step>
          </ListItem>

          <ListItem style={{ paddingBottom: '14px' }}>
            <Step
              title="Upload your file"
              stepNumber="3"
              completed={files[0] !== undefined}
            >
              <UploadDragDrop
                files={files}
                setFiles={setFiles}
                uploadDisabled={!form.strategy || !form.creditorId}
                hideUploadButton={true}
              />
            </Step>
          </ListItem>
        </List>
      </ModalDialog>
    </>
  )
}

export default LoadAccountsModal
